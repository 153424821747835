import * as React from "react"
import Layout from '../../templates/layout-standard'
import Seo from '../../components/seo'
import EnrollNowNav from '../../components/nav/EnrollNow'
import SideBar from '../../components/sidebar'
import LearnMore from '../../components/learnmore'
import SpeakWithAdvisor from '../../components/ui/SpeakWithAdvisor'
import '../../assets/css/screen.css'

const WhatHappensAferIEnroll = ({ location }) => (

	<Layout location={location} heading1="Enroll Now" heading2={['We make Medicare easy, ', <span>right from the start.</span>]}>

		<Seo title="What Happens After I Enroll | Enroll Now" meta={[{description: 'Medicare experts at MVP have been offering Medicare plans in Vermont that are consistently highly rated by the federal Medicare program for quality, service and customer satisfaction.'}]} bodyclass="enroll subpage" />
		<EnrollNowNav />
			
		<div class="constrained">
			<div class="content-constrained">
				<div class="flexwrap_subpage">
				
					<main>
						<h3>What Happens After I Enroll?</h3>
						<p>Once you join a UVM Health Advantage Medicare Advantage Plan, you will receive these things by mail:</p>

						<p><b>A confirmation letter </b>
						from MVP Health Care in about 10 days, letting you know we received your application and that Medicare has approved your enrollment in your UVM Health Advantage Medicare Advantage plan.</p>
						<ul>
							<li>You can use this letter at doctor appointments until your UVM Health Advantage Member ID card arrives.</li>
						</ul>

						<p><b>Your UVM Health Advantage Member ID card</b></p>
						<ul>
							<li>Always carry and show your UVM Health Advantage membership card when you visit your doctor, pharmacy, or dentist.</li>
							<li>Keep your Medicare card at home for your records. </li>
						</ul>

						<p><b>A verification letter </b>
						from MVP Health Care, if you are leaving an employer group plan &ndash; as required by Medicare to verify that you are familiar with the terms of your new health plan.</p>

						<h3 class="small">A note about prior authorization </h3>
						<p>Prior authorization is a process in which MVP Health Care works with you and your doctors to make sure you receive medically-necessary, high-quality medical treatment at a reasonable cost. Some services require prior authorization by MVP Health Care regardless of whether these services are given by MVP Health Care contracted or non-contracted providers.</p>

						<p>Some examples of services needing prior authorization include:</p>
						<ul>
							<li>Diagnostic services, such as CT scans and MRIs </li>
							<li>Admissions to transitional care units, acute rehabilitation, and skilled nursing facilities</li>
							<li>Durable medical equipment</li>
							<li>Home care services</li>
							<li>Implants and internal prosthetics</li>
							<li>Select prescription drugs </li>
						</ul>

						<p>Most often, your family doctor will begin the process and request authorization whenever it is needed. If you need or want a medical service not available from a provider who contracts with UVM Health Advantage, you may refer yourself to a non-contracted provider. </p>
						<p><b>Remember that it may cost you more to receive medical services from a provider who is not contracted with UVM Health Advantage.</b></p>

						<LearnMore showonly="kit" />
						<SpeakWithAdvisor />
					</main>

					<SideBar location={location} />
				</div>
			</div>
		</div>
	</Layout>
)

export default WhatHappensAferIEnroll
